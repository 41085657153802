<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      ref="cardAction"
      action-refresh
      no-body
    >
      <div class="pl-2 pr-2 mt-4">
        <b-row>
          <b-col
            cols="12"
          >
            <b-button
              id="btn-add-group"
              v-b-modal.modal-form
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              block
              variant="outline-primary"
              class="cx-dash-border mb-1 mt-1"
              @click="toAdd"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />新增
            </b-button>
          </b-col>
        </b-row>
        <!-- Table Top -->
        <el-table
          class="el-table_dashed"
          :data="cateList"
          style="width: 100%;margin-bottom: 20px;"
          :header-cell-style="{background:'#f3f2f7',fontSize: '0.857rem', color: '#6e6b7b',textAlign: 'inherit', lineHeight: '15.5px !important', paddingTop: '2px',paddingBottom: '2px' }"
          row-key="id"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
          <el-table-column
            prop="id"
            label="分类ID"
            sortable
            width="180">
          </el-table-column><el-table-column
            prop="key"
            label="分类标识"
            sortable
            width="180">
          </el-table-column>
          <el-table-column
            prop="name"
            label="分类名称"
            sortable
            width="180">
          </el-table-column>
          <el-table-column
            prop="description"
            label="描述">
          </el-table-column>
          <el-table-column
            prop="actions"
            label="操作">
            <template slot-scope="scope">
              <b-tooltip
                title="编辑"
                :target="`group-row-${scope.row.id}-edit-icon`"
              />
              <b-button
                :id="`group-row-${scope.row.id}-edit-icon`"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="btn-icon rounded-circle"
                variant="flat-primary"
                v-b-modal.modal-form
                @click="toEdit(scope.row)">
                <feather-icon icon="Edit3Icon" size="16"/>
              </b-button>
              <b-button
                :id="`group-row-${scope.row.id}-remove-icon`"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="btn-icon rounded-circle"
                variant="flat-primary">
                <feather-icon icon="Trash2Icon" size="16"/>
              </b-button>
              <b-tooltip
                title="删除"
                :target="`group-row-${scope.row.id}-remove-icon`"
              />
              <b-button
                :id="`group-row-${scope.row.id}-parent-icon`"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="btn-icon rounded-circle"
                variant="flat-primary"
                v-b-modal.modal-form
                @click="isNew = true,parentId = scope.row.id">
                <feather-icon icon="AnchorIcon" size="16"/>
              </b-button>
              <b-tooltip
                title="添加下一级"
                :target="`group-row-${scope.row.id}-parent-icon`"
              />
              <x-popconfirm
                :target="`group-row-${scope.row.id}-remove-icon`"
                content="确定要删除该分类吗？"
                triggers="focus"
                @enter="remove(scope.row.id)"
              >
              </x-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </b-card>
    <b-modal
      id="modal-form"
      ref="my-modal"
      title-class="x-text-bold"
      body-class="pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title="保存"
      cancel-title="取消"
      centered
      :title="isNew? '新增': '编辑'"
      :visible="modalFlag"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <product-category-form ref="refFormObserver" :is-new="isNew" :category="formData"></product-category-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, VBToggle, BTooltip, BModal,
} from 'bootstrap-vue'
import {
  tree, add, edit, remove,
} from '@/api/device-manage/product-category'
import {
  formatDate2,
} from '@/api/timeFormatUtils'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import XPopconfirm from '@core/components/cx/popconfirm/XPopconfirm.vue'
import ProductCategoryForm from './ProductCategoryForm.vue'

export default {
  filters: {
    formatDate(time) {
      const date = new Date(time)
      return formatDate2(date, 'yyyy-MM-dd hh:mm:ss')
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTooltip,
    XPopconfirm,
    BModal,
    ProductCategoryForm,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      searchQuery: undefined,
      total: 0,
      currentPage: 1,
      perPage: 9,
      sortBy: 'id',
      isNew: true,
      modalFlag: false,
      searchName: undefined,
      cateList: [],
      formData: {
        id: undefined,
        key: undefined,
        name: undefined,
        description: undefined,
      },
      parentId: undefined,
      required,
      group: {},
      viewId: undefined,
    }
  },
  created() {
    this.page()
  },
  methods: {
    page() {
      tree().then(resp => {
        this.cateList = resp.data.result
      })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          if (this.isNew) {
            if (this.parentId !== undefined) {
              this.formData.parentId = this.parentId
            }
            add(this.formData).then(() => {
              this.$xtoast.success('新增分类成功')
              this.page()
            })
          } else {
            edit(this.formData).then(() => {
              this.$xtoast.success('编辑分类成功')
              this.page()
            })
          }
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle('#btn-add-group')
          })
        }
      })
    },
    toEdit(item) {
      this.isNew = false
      this.formData = this._.cloneDeep(item)
      this.modalFlag = true
    },
    // 删除分组
    remove(id) {
      remove(id).then(() => {
        this.$xtoast.success('删除分类成功')
        this.page()
      })
    },
    resetModal() {
      this.modalFlag = false
      this.formData = {
        key: undefined,
        id: undefined,
        name: undefined,
        description: undefined,
      }
      this.parentId = undefined
    },
    toAdd() {
      this.formData = {
        key: undefined,
        id: undefined,
        name: undefined,
        description: undefined,
      }
      this.isNew = true
      this.modalFlag = true
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
