<template>
  <x-form-validator ref="refFormObserver">
    <x-form-feild label="分类ID">
      <b-form-input
        id="id"
        class="form-control-lg"
        v-model="formData.id"
        :disabled="!isNew"
        trim
        placeholder="请输入分类ID"
      />
    </x-form-feild>
    <x-form-feild label="分类标识" require>
      <b-form-input
        id="key"
        class="form-control-lg"
        v-model="formData.key"
        trim
        placeholder="请输入分类标识"
      />
    </x-form-feild>
    <x-form-feild label="分类名称" require>
      <b-form-input
        id="name"
        class="form-control-lg"
        v-model="formData.name"
        trim
        placeholder="请输入分类名称"
      />
    </x-form-feild>
    <x-form-feild label="描述">
      <b-form-textarea
        id="description"
        v-model="formData.description"
        trim
        placeholder="请输入描述"
      />
    </x-form-feild>
  </x-form-validator>
</template>

<script>
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import {
  BFormInput, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    XFormValidator,
    XFormFeild,
    BFormInput,
    BFormTextarea,
  },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    category: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: this.category,
    }
  },
  methods: {
    validate() {
      return this.$refs.refFormObserver.validate()
    },
    hide() {
      this.formData = {
        id: undefined,
        key: undefined,
        description: undefined,
        name: undefined,
        parentId: undefined,
      }
      this.$refs.refFormObserver.reset()
    },
  },
}
</script>

<style lang="scss">
</style>
