import request from '@/auth/jwt/useJwt'

export function query(parentId) {
  parentId = encodeURIComponent(parentId)
  return request.axiosIns({
    url: `/device/category/_query?sorts[0].name=id&sorts[0].order=desc&terms[0].column=parentId&terms[0].termType=eq&terms[0].value=${parentId}`,
    method: 'get',
  })
}
export function tree() {
  return request.axiosIns({
    url: 'device/category/_tree?paging=false&sorts[0].name=id&sorts[0].order=desc',
    method: 'get',
  })
}

export function add(data) {
  return request.axiosIns({
    url: '/device/category',
    method: 'post',
    data,
  })
}

export function edit(data) {
  return request.axiosIns({
    url: '/device/category',
    method: 'patch',
    data,
  })
}

export function remove(id) {
  return request.axiosIns({
    url: `/device/category/${id}`,
    method: 'delete',
  })
}
