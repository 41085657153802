function padLeftZero(str) {
  // eslint-disable-next-line
  return ('00' + str).substr(str.length);
}
export function aaa() {
  return 'aaa'
}
// eslint-disable-next-line
export function formatDate2(date, fmt) {
  if (/(y+)/.test(fmt)) {
    // eslint-disable-next-line
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  }
  // eslint-disable-next-line
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      // eslint-disable-next-line
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
    }
  }
  return fmt
}
