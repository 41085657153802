<template>
  <b-popover
    ref="popover"
    :target="target"
    :triggers="triggers"
    :show.sync="isShow"
    custom-class="pop-confirm"
    placement="auto"
    @hidden="onHidden">
    <div class="d-flex flex-column popover-div">
      <div class="d-flex align-items-start xpopover-body">
        <b-avatar variant="danger" text="!" class="popover-icon"/>
        <span class="popover-content">{{ content }}</span>
      </div>
      <div class="d-flex align-items-center justify-content-end mt-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-secondary" size="sm" class="mr-1" @click="onHidden">取消</b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary" size="sm" @click="onEnter">确认</b-button>
      </div>
    </div>
  </b-popover>
</template>

<script>
import {
  BPopover, BAvatar, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BPopover,
    BAvatar,
    BButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    // click', 'hover', 'focus'
    triggers: {
      type: String,
      default: 'click',
    },
    content: {
      type: String,
      default: '确定要执行此操作吗?',
    },
    target: {
      type: String,
      default: '',
    },
  },
  watch: {
    show() {
      this.isShow = this.show
    },
  },
  data() {
    return {
      isShow: false,
    }
  },
  methods: {
    hide() {
      this.isShow = false
    },
    onHidden() {
      this.isShow = false
      this.$emit('hidden')
    },
    onEnter() {
      this.$emit('enter')
      this.onHidden()
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/base/bootstrap-extended/_variables.scss';
  @import '@core/scss/base/components/_variables-dark.scss';
  .pop-confirm {
    border-radius: 0.358rem !important;
    // popover header style
    .popover-header {
      background-color: #fff !important;
    }
    // popover body style
    .popover-body {
      border: none !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .popover-div{
      margin: 0.1rem 0.2rem 0.1rem 0.2rem;
    }
    .xpopover-body{
      margin-top: .5rem;
    }
    .popover-icon{
      margin-right:.5rem ;
      width: 1.1rem;
      height: 1.1rem;
    }
    .popover-content{
      color: rgba(0,0,0,.85);
      font-size: 0.9rem;
    }

    // popover bottom arrow color like header
    &.bs-popover-bottom {
      margin-top: .8rem;
      /*.arrow:after {*/
        /*border-bottom-color: #fff !important;*/
      /*}*/
    }
    &.bs-popover-top {
      margin-bottom: .8rem;
      /*.arrow:after {*/
        /*border-bottom-color: #fff !important;*/
      /*}*/
    }
    &.bs-popover-left {
      margin-right: .8rem;
      /*.arrow:after {*/
        /*border-bottom-color: #fff !important;*/
      /*}*/
    }
    &.bs-popover-right {
      margin-left: .8rem;
      /*.arrow:after {*/
        /*border-bottom-color: #fff !important;*/
      /*}*/
    }
  }
  .dark-layout {
    .popover-content{
      color: rgba(255,255,255,.65);
    }
    .pop-confirm{
      box-shadow: $theme-dark-box-shadow !important;
      // popover bottom arrow color like header
      &.bs-popover-bottom {
        //.arrow:after {
        //  border-bottom-color: $theme-dark-card-bg !important;
        //}
      }
      &.bs-popover-top {
        // .arrow:after {
        //   border-bottom-color: $theme-dark-card-bg !important;
        //}
      }
      &.bs-popover-left {
        //.arrow:after {
        //  border-bottom-color: $theme-dark-card-bg !important;
        //}
      }
      &.bs-popover-right {
        //.arrow:after {
        //  border-bottom-color: $theme-dark-card-bg !important;
        // }
      }
    }
  }
</style>
